.swiper-container {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  margin-top: 30px;
  position: relative;
}

.swiper {
  overflow: hidden;
  width: 80%;
  height: 40%;
}

.swiper-slide {
  background-position: center;
  background-size: cover;
}

.react-player {
  width: 35em;
  height: 35em;
  user-select: none;
  object-fit: cover;
  transform: scale(0.6);
  border-radius: 1em;
  overflow: hidden;
}

.swiper-slide-active .react-player {
  transform: translateX(18px) scale(1);
}

.youtube_caption {
  position: absolute;
  bottom: 0px;
  font-family: Poppins;
  font-size: 24px;
  font-weight: 600;
  text-align: center;
  color: #d71a63;
  bottom: -10px !important;
}

.swiper-slide-prev .youtube_caption,
.swiper-slide-next .youtube_caption {
  color: rgba(0, 0, 0, 0.5);
  font-size: 18px;
}

.swiper-slide-prev,
.swiper-slide-next {
  opacity: 0.6;
  transform: scale(0.4);
  width: 35em !important;
  pointer-events: none;
}



.swiper-slide-shadow-left,
.swiper-slide-shadow-right {
  display: none;
}

.slider-controller .swiper-pagination {
  bottom: -7px !important;
}

/* Responsive Styles */
@media only screen and (max-width: 1024px) {
  .swiper {
    width: 80vw;
  }
  .react-player {
    width: 25em;
    height: 32em ;
  }

  .swiper-slide-prev,
  .swiper-slide-next {
    width: 20em !important;
    text-align: center !important;
  }


  .swiper-slide-active .react-player {
    width: 50em !important;
    transform:  scale(.8);
    
  }
}

@media only screen and (max-width :850px) {
  .swiper-container{
    margin-top: -41px;
  }
  .swiper {
    width: 90vw !important;
    text-align: center !important;
  }

  .react-player {
    width: 35em !important;
    height: 35em !important;
  }
  .swiper-slide-active .react-player {
    width: 39em !important;
    height: 30em !important;
    transform: translateY(0px) scale(.7);
  }

  .swiper-slide-prev,
  .swiper-slide-next {
    width: 25em !important;
    transform: scale(.7);
    text-align: center !important;
  }

  /* .swiper-slide-prev .youtube_caption{
    margin-left: 124px !important;
    margin-top: -78px !important;
  }

  .swiper-slide-next .youtube_caption{
    margin-left: 160px !important;
    margin-top: -78px !important;
  } */

  .swiper-slide-active .youtube_caption {
      font-size: 14px !important;
  }
}

@media only screen and (max-width: 430px) {
  .swiper-container{
    width: 100%;
  }
  .react-player {
    width: 25em !important;
    height: 15em !important;
  }
  .swiper-slide-active .react-player {
    width: 25em !important;
    height: 15em !important;
  }

  .swiper-slide-prev,
  .swiper-slide-next {
    width: 14em !important;
    transform: scale(.4);
  }

  .swiper-slide-active .react-player {
    width: 25em !important;
    transform: translateY(0px) scale(0.7);
  }

  /* .slider-controller .swiper-pagination{
    margin-top: 50px !important;
  }
   */
  /* .swiper-slide-prev .youtube_caption{
    margin-left: 124px !important;
    margin-top: -78px !important;
  }

  .swiper-slide-next .youtube_caption{
    margin-left: 160px !important;
    margin-top: -78px !important;
  }  */

}

@media only screen and (max-width: 600px) {
  .swiper-container{
    width: 100%;
  }
  

  .swiper-slide-active .react-player {
    width: 25em !important;
    height: 15em !important;
  }

  .swiper-slide-prev,
  .swiper-slide-next {
    width: 14em !important;
    transform: scale(.4);
  }

  .swiper-slide-active .react-player {
    transform: translateY(0px) scale(0.7);
  }

  /* .youtube_caption{
    font-size:12px !important;
  } */
}

.prev-icon{
  position: absolute;
  top: 40%;
  left: 0px;
  background: none;
  border: none;
  font-size: 24px;
  cursor: pointer;
  width: 41px;
  height: 46px;
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 8px;
  box-shadow: 0 16px 32px 0 rgba(221, 230, 237, 0.4);
  background-color: var(--white);
  color: red;
  cursor: pointer;
z-index: 1000;
}

.next-icon{
  position: absolute;
  top: 40%;
  right: 0px;
  background: none;
  border: none;
  font-size: 24px;
  cursor: pointer;
  width: 41px;
  height: 46px;
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 8px;
  box-shadow: 0 16px 32px 0 rgba(221, 230, 237, 0.4);
  background-color: var(--white);
  color: red;
  cursor: pointer;
  z-index: 1000;
}

.slider-controller{
  margin-top: 50px !important;
}

/* @media only screen and (min-width:1024px) {
  .swiper-slide-prev .youtube_caption{
    margin-left: 142px !important;
    margin-top: -55px !important;
  }

  .swiper-slide-next .youtube_caption{
    margin-left: -64px !important;
    margin-top: -55px !important;
  } 
} */

@media (min-width: 375px) { 
  .youtube_caption {
    font-size: 12px !important;
    margin-top: -16px !important;
  }
  .swiper-slide-prev .youtube_caption{
    margin-left: 72px !important;
    
  }

  .swiper-slide-next .youtube_caption{
   margin-top: -16px !important;
  
 }
}

@media (min-width: 576px) { 
  .youtube_caption {
    font-size: 12px !important;
  }
  .swiper-slide-prev .youtube_caption{
    margin-left: 142px !important;
    margin-top: -55px !important;
  }

  .swiper-slide-next .youtube_caption{
    margin-left: -64px !important;
    margin-top: -55px !important;
  } 
 }

/* // Medium devices (tablets, 768px and up) */
@media (min-width: 768px) { 
  .youtube_caption {
    font-size: 14px !important;
  }
 }

/* // Large devices (desktops, 992px and up) */
@media (min-width: 992px) { 
  .youtube_caption {
    font-size: 18px !important;
  }
  .swiper-slide-prev .youtube_caption{
    margin-left: -60px !important;
    margin-top: -55px !important;
  }

  .swiper-slide-next .youtube_caption{
    margin-right: -79px !important;
    margin-top: -55px !important;

  } 
 }

/* // X-Large devices (large desktops, 1200px and up) */
@media (min-width: 1200px) { 
  .youtube_caption {
    font-size: 24px !important;
  }
  .swiper-slide-prev .youtube_caption{
    margin-left: 142px !important;
    margin-top: -55px !important;
  }

  .swiper-slide-next .youtube_caption{
    margin-right: 0px !important;
    margin-top: -55px !important;
  } 
 }

/* // XX-Large devices (larger desktops, 1400px and up) */
@media (min-width: 1400px) { 
  .youtube_caption {
    font-size: 24px !important;
    margin: -29px !important;
  }
  .swiper-slide-prev .youtube_caption{
    margin: 14px 9px 33px 189px !important;
  }

  .swiper-slide-next .youtube_caption{
    margin: 32px 12px 33px -61px !important
  } 
 }
