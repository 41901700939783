:root {
    --radius: 30px;
}

.ant-segmented {
    border-radius: var(--radius) !important;
    border: 1px solid #ffdce9 !important;
    font-size: clamp(0.75rem, 0.6346rem + 0.5128vw, 1rem) !important;
    line-height: 1.5 !important;
    background-color: #ffffff !important;
}

.ant-segmented-thumb {
    border-radius: var(--radius) !important;
    background-color: #d71a63 !important;
}

.ant-segmented-item-selected {
    color: #ffffff !important;
    background-color: #d71a63 !important;
    border-radius: var(--radius) !important;
    /* font-weight: 600 !important; */
}

.ant-segmented-item {
    color: #242424;
    border-radius: var(--radius) !important;
}